import React from 'react';

const UploadArrowIcon = ({ strokeColor = '#155EEF', fill = 'transparent', onClick }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}>
      <path
        d="M13 9V9.8C13 10.9201 13 11.4802 12.782 11.908C12.5903 12.2843 12.2843 12.5903 11.908 12.782C11.4802 13 10.9201 13 9.8 13H4.2C3.07989 13 2.51984 13 2.09202 12.782C1.71569 12.5903 1.40973 12.2843 1.21799 11.908C1 11.4802 1 10.9201 1 9.8V9M10.3333 4.33333L7 1M7 1L3.66667 4.33333M7 1V9"
        stroke={strokeColor ? strokeColor : '#155EEF'}
        stroke-width="1.2"
        fill={fill ? fill : 'transparent'}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default UploadArrowIcon;
