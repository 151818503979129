import React from 'react';
import { NavLink } from 'react-router-dom';
import { useBrowseNodeMap } from '../../hooks/useMaterialCategory';

const useCategoryID = (cat) => {
  const { categoriesById } = useBrowseNodeMap();

  const filteredID = Object.values(categoriesById).find((item, idx) => item?.title === cat);

  return filteredID?.id;
};

const getCurrencyValue = (num) => {
  return new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  }).format(+num);
};

export const getNumberFormat = (num) => {
  return new Intl.NumberFormat('en-IN', {
    maximumSignificantDigits: 3,
  }).format(+num);
};

export const getNumberFomatWithoutRoundOff = (num) => {
  return new Intl.NumberFormat('en-IN').format(+num);
};

export const recyclerColumns = [
  {
    Header: 'Recycler Name',
    accessor: 'vendorName',
    Cell: ({ row, cell }) => {
      return (
        <NavLink to={`/service-provider/${row?.original?.serviceProviderId}`}>
          {cell?.value}
        </NavLink>
      );
    },
  },
  {
    Header: 'Registered State',
    accessor: 'registeredState',
  },
  {
    Header: 'Type',
    accessor: 'vendorType',
  },
  {
    Header: 'Category',
    accessor: 'category',
    Cell: ({ cell }) => {
      return (
        <span>
          {cell?.value
            ?.replace('Category', 'Cat')
            ?.replace(': ', '')
            ?.replace('Rigid', '')
            ?.replace('Flexible', '') ?? '-'}
        </span>
      );
    },
  },
  {
    Header: 'Rate / KG',
    accessor: 'rate',
    Cell: ({ cell }) => {
      return (
        <span style={{ display: 'flex', justifyContent: 'right' }}>
          {getCurrencyValue(Number(cell?.value).toFixed(2))}
        </span>
      );
    },
  },
  {
    Header: 'Available (MT)',
    accessor: 'pendingQty',
    Cell: ({ cell }) => {
      return (
        <spa style={{ display: 'flex', justifyContent: 'right' }}>
          {getNumberFormat(Number(cell?.value).toFixed(0))}
        </spa>
      );
    },
  },
  {
    Header: 'Recykal Capacity (MT)',
    accessor: 'allocatedToRecykal',
    Cell: ({ cell }) => {
      return (
        <span style={{ display: 'flex', justifyContent: 'right' }}>
          {getNumberFormat(Number(cell?.value).toFixed(0))}
        </span>
      );
    },
  },
  {
    Header: 'Used (MT)',
    accessor: 'utilisedQty',
    Cell: ({ cell, row }) => {
      let str = '';
      const ids = useCategoryID(row?.original?.category);
      if (ids > 0) str = `browseNodeIds=${ids}`;
      return (
        <NavLink to={`/work-orders?${str}&toCustomerIds=${row?.original?.serviceProviderId}`}>
          <span style={{ display: 'flex', justifyContent: 'right' }}>
            {getNumberFormat(Number(cell?.value).toFixed(0))}
          </span>
        </NavLink>
      );
    },
  },
  {
    Header: 'CTO',
    accessor: 'ctoAvailable',
  },
  {
    Header: 'PWM',
    accessor: 'pwmAvailable',
  },
  {
    Header: 'Agreement',
    accessor: 'agreementAvailable',
  },
];

export const categoryCol = [
  {
    Header: 'Category',
    accessor: 'category',
  },
  {
    Header: 'Available Capacity (MT)',
    accessor: 'availableCapacity',
  },
  {
    Header: 'Unplanned Demand (MT)',
    accessor: 'unplannedDemand',
  },
  {
    Header: 'Capacity Gap (MT)',
    accessor: 'capacityGap',
  },
];
