import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

export default function TooltipOnHover({ msg, children, dataStyle }) {
  return (
    <OverlayTrigger
      key={'top'}
      placement={'top'}
      overlay={
        <Popover
          id="popover-positioned-top"
          className="p-1"
          style={{ color: '#fff', backgroundColor: '#0C111D' }}>
          {msg}
        </Popover>
      }>
      <div style={dataStyle}>{children}</div>
    </OverlayTrigger>
  );
}
