import React, { Component } from 'react';
import classNames from 'classnames';

export default function (ComposedComponent) {
  class NetworkDetector extends Component {
    state = {
      isDisconnected: false,
      connecting: false,
    };

    componentDidMount() {
      this.handleConnectionChange();
      window.addEventListener('online', this.handleConnectionChange);
      window.addEventListener('offline', this.handleConnectionChange);
    }

    componentWillUnmount() {
      window.removeEventListener('online', this.handleConnectionChange);
      window.removeEventListener('offline', this.handleConnectionChange);
    }

    handleConnectionChange = () => {
      const condition = navigator.onLine ? 'online' : 'offline';
      if (condition === 'online') {
        this.setState({ connecting: true });
        const webPing = setInterval(() => {
          fetch('//google.com', {
            mode: 'no-cors',
          })
            .then(() => {
              this.setState({ isDisconnected: false, connecting: false }, () => {
                return clearInterval(webPing);
              });
            })
            .catch(() => this.setState({ isDisconnected: true, connecting: false }));
        }, 2000);
        return;
      }

      return this.setState({ isDisconnected: true });
    };

    render() {
      const { isDisconnected, connecting } = this.state;
      return (
        <div>
          {
            isDisconnected ? (
              <div
                className={classNames('internet-error animated slideInDown', {
                  'bg-danger': isDisconnected && !connecting,
                  'bg-warning': connecting,
                })}>
                <span>{connecting ? 'Connecting...!' : 'You are not connected to internet'}</span>
              </div>
            ) : null
            /*<div className="internet-error animated slideOutUp bg-success delay-1s">
              <span>Connected!</span>
            </div>*/
          }
          <ComposedComponent {...this.props} />
        </div>
      );
    }
  }

  return NetworkDetector;
}
