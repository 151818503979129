import React from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { isEmpty } from 'lodash';

function ApproveWorkOrderModal(props) {
  const {
    isOpen,
    hideModal,
    approvalComments,
    setApprovalComments,
    approveRejectHandler,
    loading,
  } = props;

  return (
    <Modal show={isOpen} onHide={hideModal} size="md">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-sm" className="p-2">
          <h4>Approving Work order?</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="  modal-register-page">
        <Form.Group controlId="commentForm">
          <Form.Label
            style={{
              fontSize: '14px',
              fontWeight: 500,
              color: '#344054',
            }}>
            Specify Reason
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            placeholder="Enter your comment here"
            value={approvalComments}
            maxLength={600}
            onChange={(e) => setApprovalComments(e.target.value)}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer className="border-top-0">
        <Button
          className={'float-right text-white font-bold mr-3'}
          size="lg"
          disabled={isEmpty(approvalComments) || loading}
          onClick={() => approveRejectHandler('IN_PROGRESS')}>
          {loading ? (
            <div>
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              <span> Approving Work Order</span>
            </div>
          ) : (
            'Approve Work Order'
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ApproveWorkOrderModal;
