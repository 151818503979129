import { faRupeeSign } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import find from 'lodash/find';
import React from 'react';
import { Button, Table } from 'react-bootstrap';
import { useSortBy, useTable } from 'react-table';
import copyicon from '../../../assets/img/copy.svg';
import deleteicon from '../../../assets/img/delete.svg';
import { useAuthority } from '../../../shared/Components/HasAuthority/HasAuthorityNew';
import {
  AUTHORITY2,
  BRAND_WO_STATUS_WITHOUT_COLUMNS,
  PROCESSING_TYPE_OPTIONS,
  UNIT_TYPES,
} from '../../../shared/Constants/Constants';
import { roundOffAmount } from '../../../shared/Utils/Utils';
import { ColumnSort, DropDownCell, InputCell } from '../TableCells/TableCells';
import './WorkOrderItemsEdit.scss';

function BrandWorkOrderItemsEdit(props) {
  const {
    basicDetails,
    updateHandler,
    addNewRowHandler,
    deleteRowHandler,
    copyRowHandler,
    deliveryExists,
    rowsToHighlight,
    browseNodes,
    regions,
    addRow,
    isHUL,
    isUserPRO,
    handleKeyDown,
    isNotMandatory,
    maxValueByCategory,
    isEdit,
    workOrderForm,
  } = props;

  const isProgress = basicDetails?.status === 'IN_PROGRESS';
  const { hasAuth } = useAuthority();
  const isBrand = hasAuth([AUTHORITY2.BRAND]);
  const isPRO = hasAuth([AUTHORITY2.PRO]);

  const sumForCategory = {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
  };

  basicDetails.workOrderItems.forEach((li) => {
    sumForCategory[li.browseNodeId] += li.qty;
  });

  const categoriesWithExceededQty = Object.keys(sumForCategory)
    .filter((catId) => sumForCategory[catId] > maxValueByCategory[catId])
    ?.at(0);

  const categoriesWithPendingQty = Object.keys(sumForCategory)
    .filter((catId) => sumForCategory[catId] < maxValueByCategory[catId])
    ?.at(0);

  const createKey = (browseNodeId, itemId, stateId, districtId, creditType) => {
    return `${browseNodeId ?? ''} ${itemId ?? ''} ${stateId ?? ''} ${districtId ?? ''} ${
      creditType ?? ''
    }`;
  };
  const map = new Map();
  let isValid = true;
  basicDetails.workOrderItems.forEach((value) => {
    const key = createKey(
      value['browseNodeId'],
      value['itemId'],
      value['stateId'],
      value['districtId'],
      value['creditType']
    );
    isValid = isValid & !map.has(key);
    map.set(key, true);
  });

  const getMaterialCategories = () => {
    return browseNodes.map((item) => {
      return { label: item.title, value: item.id };
    });
  };

  const getMaterials = (cellInfo) => {
    return find(browseNodes, {
      id: cellInfo?.row?.values?.browseNodeId,
    })?.items?.map((item) => {
      return { label: item.name, value: item.id };
    });
  };

  const getStates = (cellInfo) => {
    return cellInfo?.row?.values?.itemId
      ? regions.map((item) => {
          return { label: item.name, value: item.id ?? null };
        })
      : [];
  };

  const getDistricts = (cellInfo) => {
    return find(regions, {
      id: Number(cellInfo?.row?.values?.stateId),
    })?.districts?.map((item) => {
      return { label: item.name, value: item.id ?? null };
    });
  };

  const getTargetMaterials = (materialCategory) => {
    if (materialCategory?.row?.values?.browseNodeId) {
      return browseNodes
        ?.find((data) => data?.id === materialCategory?.row?.values?.browseNodeId)
        ?.items?.map((data) => {
          return {
            label: data?.name,
            value: data?.id,
          };
        });
    }
  };

  const getTargetStates = () => {
    return regions?.map((data) => {
      return {
        label: data?.name,
        value: data?.id,
      };
    });
  };

  const getTargetDistricts = (stateValue) => {
    if (stateValue) {
      return regions
        ?.find((data) => data?.id === stateValue)
        ?.districts?.map((data) => {
          return {
            label: data?.name,
            value: data?.id,
          };
        });
    }
  };

  const isPlanningStarted = (cellInfo) => {
    return cellInfo?.row?.original?.plannedQty && cellInfo?.row?.original?.plannedQty !== 0;
  };

  const checkFullFilledQtyExists = (fullfilledQty) =>
    isUserPRO ? deliveryExists : fullfilledQty > 0;

  const isAllocateTargetLessThanFullfilledQty = (allocateTarget, fullfilledQty) =>
    allocateTarget < fullfilledQty;

  const isPlanningDone =
    isProgress &&
    !categoriesWithPendingQty &&
    !basicDetails?.workOrderItems?.some((li) => li.plannedQty !== li.qty);

  let columns = React.useMemo(
    () => [
      {
        Header: 'Material category',
        accessor: 'browseNodeId',
        hidden: true,
        Cell: (cellInfo) => {
          const categoriesForTheCell = getMaterialCategories();
          return (
            <>
              <div className={'d-inline-flex  align-items-center'}>
                {isProgress && !!cellInfo?.row?.original?.id ? (
                  browseNodes?.find((data) => data?.id === cellInfo?.cell?.value)?.title
                ) : (
                  <DropDownCell
                    cellInfo={cellInfo}
                    controlName="browseNodeId"
                    updateHandler={updateHandler}
                    required={!isNotMandatory}
                    list={categoriesForTheCell?.filter(
                      (item) =>
                        !isProgress ||
                        cellInfo?.cell?.value === item.value ||
                        maxValueByCategory?.[item.value] > 0
                    )}
                    disabled={isProgress && !!cellInfo?.row?.original?.id}
                  />
                )}
                {!cellInfo?.cell?.value && (
                  <p
                    style={{
                      color: 'red',
                      marginLeft: '2px',
                      marginBottom: '0px',
                    }}>
                    *
                  </p>
                )}
              </div>
            </>
          );
        },
      },
      {
        Header: 'Material Type',
        accessor: 'itemId',
        Cell: (cellInfo) => {
          return (
            <>
              <div className={'d-inline-flex  align-items-center'}>
                <DropDownCell
                  cellInfo={cellInfo}
                  updateHandler={updateHandler}
                  controlName="itemId"
                  disabled={
                    !getMaterials(cellInfo)?.length ||
                    (isProgress && isPlanningStarted(cellInfo) && !!cellInfo?.row?.original?.id)
                  }
                  required={!isNotMandatory}
                  list={getMaterials(cellInfo)}
                />
                {!cellInfo?.cell?.value && (
                  <p
                    style={{
                      color: 'red',
                      marginLeft: '2px',
                      marginBottom: '0px',
                    }}>
                    *
                  </p>
                )}
              </div>
              {cellInfo?.cell?.value === undefined && (
                <p
                  style={{
                    color: 'red',
                    marginTop: '5px',
                    textAlign: 'center',
                    marginBottom: '0px',
                  }}>
                  Mismatch
                </p>
              )}
            </>
          );
        },
      },

      {
        Header: 'State',
        accessor: 'stateId',
        Cell: (cellInfo) => {
          return (
            <>
              <div className={'d-inline-flex  align-items-center'}>
                <DropDownCell
                  cellInfo={cellInfo}
                  controlName="stateId"
                  required={false}
                  updateHandler={updateHandler}
                  list={getStates(cellInfo)}
                  disabled={
                    isProgress && isPlanningStarted(cellInfo) && cellInfo?.row?.original?.id
                  }
                />
              </div>
            </>
          );
        },
      },
      {
        Header: 'District',
        accessor: 'districtId',
        Cell: (cellInfo) => {
          return (
            <>
              <DropDownCell
                cellInfo={cellInfo}
                controlName="districtId"
                disabled={
                  !getDistricts(cellInfo)?.length ||
                  (isProgress && isPlanningStarted(cellInfo) && cellInfo?.row?.original?.id)
                }
                required={false}
                updateHandler={updateHandler}
                list={getDistricts(cellInfo)}
              />
              {cellInfo?.cell?.value === undefined && (
                <p
                  style={{
                    color: 'red',
                    marginTop: '5px',
                    textAlign: 'center',
                    marginBottom: '0px',
                  }}>
                  Mismatch
                </p>
              )}
            </>
          );
        },
      },
      {
        Header: 'Credit Type',
        accessor: 'creditType',
        Cell: (cellInfo) => {
          const disabled = () => {
            return ['3', '5', '7']?.includes(cellInfo?.cell?.row?.original?.browseNodeId);
          };
          return (
            <div className={'d-inline-flex  align-items-center'}>
              <DropDownCell
                cellInfo={cellInfo}
                updateHandler={updateHandler}
                list={PROCESSING_TYPE_OPTIONS}
                disabled={
                  disabled() ||
                  (!!workOrderForm?.initialValues?.workOrderItems?.[cellInfo?.cell?.row?.index]
                    ?.creditType &&
                    isProgress &&
                    isPlanningStarted(cellInfo) &&
                    cellInfo?.row?.original?.id)
                }
                required={!disabled()}
              />
              {!disabled() && !cellInfo?.cell?.value && (
                <p
                  style={{
                    color: 'red',
                    marginLeft: '2px',
                    marginBottom: '0px',
                  }}>
                  *
                </p>
              )}
            </div>
          );
        },
      },
      {
        Header: 'Quantity in Kgs',
        accessor: 'qty',
        Cell: (cellInfo) => {
          return (
            <>
              <div className={'d-inline-flex  align-items-center'}>
                {isPlanningDone ? (
                  cellInfo.cell.value ?? 0
                ) : (
                  <InputCell
                    cellInfo={cellInfo}
                    controlName="allocatedTarget"
                    required={!isNotMandatory}
                    min={cellInfo?.row?.original?.plannedQty ?? 0}
                    inputType={'number'}
                    step={'any'}
                    placeholder={'Enter target'}
                    updateHandler={updateHandler}
                    handleKeyDown={handleKeyDown}
                    isInvalid={cellInfo?.value < (cellInfo?.row?.original?.plannedQty ?? 0)}
                  />
                )}
                &nbsp;{UNIT_TYPES['KG']?.displayText}
                {!cellInfo?.cell?.value && (
                  <p
                    style={{
                      color: 'red',
                      marginLeft: '2px',
                      marginBottom: '0px',
                    }}>
                    *
                  </p>
                )}
              </div>
              {!isUserPRO && (
                <p className="allocateTargetErrorText">
                  {isAllocateTargetLessThanFullfilledQty(
                    cellInfo?.cell?.row?.original?.qty,
                    cellInfo?.cell?.row?.original?.fulfilledQty
                  ) &&
                    `Allocate Taregt is less than fullfilled qty(${cellInfo?.cell?.row?.original?.fulfilledQty})`}
                </p>
              )}
            </>
          );
        },
      },
      {
        Header: 'Planned Quantity',
        accessor: 'plannedQty',
        Cell: (cellInfo) => {
          return (
            <div className={'d-inline-flex  align-items-center'}>
              {cellInfo?.value ?? 0}&nbsp;{UNIT_TYPES['KG']?.displayText}
            </div>
          );
        },
      },
      {
        Header: 'Fulfilled Quantity',
        accessor: 'fulfilledQty',
        Cell: (cellInfo) => {
          return (
            <div className={'d-inline-flex  align-items-center'}>
              {cellInfo?.value ?? 0}&nbsp;{UNIT_TYPES['KG']?.displayText}
            </div>
          );
        },
      },
      {
        Header: 'Price per unit',
        accessor: 'pricePerUnit',
        Cell: (cellInfo) => {
          return (
            <>
              <div className={'d-inline-flex  align-items-center'}>
                <FontAwesomeIcon icon={faRupeeSign} />
                &nbsp;&nbsp;
                {cellInfo?.row?.original?.plannedQty > 0 ||
                (!!workOrderForm?.initialValues?.workOrderItems?.[cellInfo?.cell?.row?.index]
                  ?.pricePerUnit &&
                  isProgress &&
                  !!cellInfo?.row?.original?.id) ? (
                  cellInfo?.cell?.value
                ) : (
                  <InputCell
                    cellInfo={cellInfo}
                    required={!isNotMandatory}
                    min={0}
                    step={'any'}
                    controlName="pricePerUnit"
                    inputType={'number'}
                    placeholder={'Enter price'}
                    disabled={
                      cellInfo?.row?.original?.plannedQty > 0 ||
                      (!!workOrderForm?.initialValues?.workOrderItems?.[cellInfo?.cell?.row?.index]
                        ?.pricePerUnit &&
                        isProgress &&
                        !!cellInfo?.row?.original?.id)
                    }
                    updateHandler={updateHandler}
                    handleKeyDown={handleKeyDown}
                  />
                )}
                {!cellInfo?.cell?.value && (
                  <p
                    style={{
                      color: 'red',
                      marginLeft: '2px',
                      marginBottom: '0px',
                    }}>
                    *
                  </p>
                )}
              </div>
            </>
          );
        },
      },
      {
        Header: 'Amount',
        accessor: 'lineTotal',
        Cell: (cellInfo) => {
          return (
            <div className={'d-inline-flex  align-items-center mt-3'}>
              <FontAwesomeIcon icon={faRupeeSign} /> &nbsp;
              {roundOffAmount(cellInfo?.cell?.value)}
            </div>
          );
        },
      },
      {
        Header: 'PO Line Item',
        accessor: 'poLineItem',
        Cell: (cellInfo) => {
          return (
            <>
              <div className={'d-inline-flex  align-items-center'}>
                <InputCell
                  cellInfo={cellInfo}
                  required={!isNotMandatory}
                  step={'any'}
                  controlName="poLineItem"
                  inputType={'number'}
                  placeholder={'Enter po line Item'}
                  disabled={checkFullFilledQtyExists(cellInfo?.cell?.row?.original?.fulfilledQty)}
                  updateHandler={updateHandler}
                  handleKeyDown={handleKeyDown}
                  isInvalid={!cellInfo?.value}
                />
                {!cellInfo?.cell?.value && (
                  <p
                    style={{
                      color: 'red',
                      marginLeft: '2px',
                      marginBottom: '0px',
                    }}>
                    *
                  </p>
                )}
              </div>
            </>
          );
        },
      },
      {
        Header: 'Actions',
        accessor: 'none',
        Cell: (cellInfo) => {
          return (
            <div className="mt-2" style={{ pointerEvents: 'auto' }}>
              {(!isProgress ||
                (!isPlanningDone &&
                  maxValueByCategory[cellInfo?.row?.original?.browseNodeId] >
                    sumForCategory[cellInfo?.row?.original?.browseNodeId])) && (
                <img
                  src={copyicon}
                  alt="copy"
                  onClick={() => copyRowHandler(cellInfo?.row?.index)}
                />
              )}
              {(!cellInfo?.row?.original?.id || !isProgress) && (
                <img
                  className="ml-3"
                  src={deleteicon}
                  alt="delete"
                  onClick={() => deleteRowHandler(cellInfo?.row?.index)}
                />
              )}
            </div>
          );
        },
      },
    ],
    [basicDetails]
  );

  let targetColumns = React.useMemo(
    () => [
      {
        Header: 'Material category',
        accessor: 'browseNodeId',
        hidden: true,
        Cell: (cellInfo) => {
          const browseNodeValue = browseNodes?.find(
            (data) => data?.id === cellInfo?.cell?.value
          )?.title;
          return (
            <>
              <div className={'d-inline-flex  align-items-center'}>
                <p
                  style={{
                    marginBottom: '0px',
                  }}>
                  {browseNodeValue
                    ? browseNodeValue
                    : cellInfo?.cell?.value && (
                        <span style={{ color: 'red' }}>Material category not present</span>
                      )}
                </p>
                {cellInfo?.cell?.value === undefined && (
                  <p
                    style={{
                      color: 'red',
                      marginBottom: '0px',
                    }}>
                    Mismatch
                  </p>
                )}
                {cellInfo?.cell?.value === null && (
                  <p
                    style={{
                      color: 'red',
                      marginBottom: '0px',
                    }}>
                    Material category is mandatory
                  </p>
                )}
              </div>
            </>
          );
        },
      },
      {
        Header: 'Material Type',
        accessor: 'itemId',
        Cell: (cellInfo) => {
          const materialItems = getTargetMaterials(cellInfo);
          const browseNodeValue =
            cellInfo?.cell?.row?.values?.browseNodeId &&
            browseNodes
              ?.find((data) => data?.id === cellInfo?.cell?.row?.values?.browseNodeId)
              ?.items?.find((item) => item?.id === cellInfo?.cell?.row?.values?.itemId)?.name;
          return (
            <>
              <div className={'d-inline-flex  align-items-center'}>
                <p
                  style={{
                    marginBottom: '0px',
                  }}>
                  {browseNodeValue
                    ? materialItems?.find((data) => data?.value === cellInfo?.cell?.value)?.label
                    : cellInfo?.cell?.value && (
                        <span style={{ color: 'red' }}>Material item not present</span>
                      )}
                </p>
                {cellInfo?.cell?.value === undefined && (
                  <p
                    style={{
                      color: 'red',
                      marginBottom: '0px',
                    }}>
                    Mismatch
                  </p>
                )}
                {cellInfo?.cell?.value === null && (
                  <p
                    style={{
                      color: 'red',
                      marginBottom: '0px',
                    }}>
                    Material item is mandatory
                  </p>
                )}
              </div>
            </>
          );
        },
      },
      {
        Header: 'State',
        accessor: 'stateId',
        Cell: (cellInfo) => {
          const itemStates = getTargetStates(cellInfo);
          return (
            <>
              <p
                style={{
                  marginBottom: '0px',
                }}>
                {itemStates?.find((data) => data?.value === cellInfo?.cell?.value)?.label}
              </p>
              {cellInfo?.cell?.value === undefined &&
                cellInfo?.cell?.row?.values?.browseNodeId !== '3' &&
                cellInfo?.cell?.row?.values?.browseNodeId !== '5' && (
                  <p
                    style={{
                      color: 'red',
                      marginBottom: '0px',
                    }}>
                    Mismatch
                  </p>
                )}
              {cellInfo?.cell?.value === null && (
                <p
                  style={{
                    marginBottom: '0px',
                  }}>
                  NA
                </p>
              )}
            </>
          );
        },
      },
      {
        Header: 'District',
        accessor: 'districtId',
        Cell: (cellInfo) => {
          const itemDistricts = getTargetDistricts(cellInfo?.cell?.row?.values?.stateId);
          const rowDetails = basicDetails?.workOrderItems[cellInfo?.cell?.row?.index];
          return (
            <>
              <p
                style={{
                  marginBottom: '0px',
                }}>
                {itemDistricts?.find((data) => data?.value === cellInfo?.cell?.value)?.label}
              </p>
              {rowDetails?.isSameDetailsExist &&
                cellInfo?.cell?.row?.values?.browseNodeId !== '3' &&
                cellInfo?.cell?.row?.values?.browseNodeId !== '5' && (
                  <p
                    style={{
                      color: 'red',
                      marginBottom: '0px',
                    }}>
                    District level target is mandatory for this combination
                  </p>
                )}
              {cellInfo?.cell?.value === undefined &&
                cellInfo?.cell?.row?.values?.browseNodeId !== '3' &&
                cellInfo?.cell?.row?.values?.browseNodeId !== '5' && (
                  <p
                    style={{
                      color: 'red',
                      marginBottom: '0px',
                    }}>
                    Mismatch
                  </p>
                )}
              {cellInfo?.cell?.value === null && !rowDetails?.isSameDetailsExist && (
                <p
                  style={{
                    marginBottom: '0px',
                  }}>
                  NA
                </p>
              )}
            </>
          );
        },
      },
      {
        Header: 'Quantity in Kgs',
        accessor: 'qty',
        Cell: (cellInfo) => {
          return (
            <>
              <div className={'d-inline-flex  align-items-center'}>
                <p
                  style={{
                    marginBottom: '0px',
                  }}>
                  {cellInfo?.cell?.value}
                </p>
                {cellInfo?.cell?.value && <>&nbsp;{UNIT_TYPES['KG']?.displayText}</>}
                {!cellInfo?.cell?.value && (
                  <p
                    style={{
                      color: 'red',
                      marginLeft: '2px',
                      marginBottom: '0px',
                    }}>
                    Allocate the Quantity
                  </p>
                )}
              </div>
            </>
          );
        },
      },
    ],
    [basicDetails]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns: addRow ? columns : targetColumns,
      data: basicDetails.workOrderItems,
      initialState: {
        hiddenColumns: [
          !isHUL && 'poLineItem',
          !isPRO && 'sac',
          BRAND_WO_STATUS_WITHOUT_COLUMNS.includes(basicDetails?.status) && 'plannedQty',
          BRAND_WO_STATUS_WITHOUT_COLUMNS.includes(basicDetails?.status) && 'fulfilledQty',
          !isEdit && 'plannedQty',
          !isEdit && 'fulfilledQty',
        ],
      },
    },
    useSortBy
  );

  return (
    <Table
      {...getTableProps()}
      responsive
      className="table-bordered border-bottom-0 work-order-items-edit">
      <thead className="thead-light table-heading">
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                <div className={'d-flex flex-row align-items-center'}>
                  {column.render('Header')}
                  <span className={'ml-auto mr-1'}>
                    {column.canSort ? <ColumnSort column={column} /> : null}
                  </span>
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows?.length ? (
          <>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={classNames('', {
                    'highlight-row': rowsToHighlight?.includes(index),
                  })}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            })}
          </>
        ) : (
          <tr>
            <td colSpan={columns?.length}>
              <p className={'text-center m-3 text-danger'}>
                At least one target allocation is required to save.
              </p>
            </td>
          </tr>
        )}
        {addRow && (
          <tr>
            {!isPlanningDone && (
              <td className={'border-0 pl-0'}>
                <Button
                  variant={'light'}
                  className={'custom-btn-small-light'}
                  onClick={addNewRowHandler}>
                  + Add Row
                </Button>
              </td>
            )}
            <td
              colSpan={
                BRAND_WO_STATUS_WITHOUT_COLUMNS.includes(basicDetails?.status) || !isEdit ? 6 : 8
              }
              className={'text-right text-medium font-semibold border-0'}>
              Total
            </td>
            <td className={'border-bottom font-semibold'}>
              <FontAwesomeIcon icon={faRupeeSign} />
              &nbsp;
              {roundOffAmount(
                rows
                  .map((row) => row?.values?.lineTotal)
                  ?.reduce((prev, next) => roundOffAmount(prev + next), 0)
              )}
            </td>
          </tr>
        )}
        <p style={{ marginTop: '5px' }}>Count: {basicDetails?.workOrderItems?.length}</p>
      </tbody>
      {isEdit && isProgress && categoriesWithExceededQty ? (
        <tfoot>
          <tr>
            <td colSpan={8}>
              <span className="error-style" style={{ fontSize: '1rem', textWrap: 'nowrap' }}>
                The quantity for{' '}
                {browseNodes?.find((data) => data?.id === categoriesWithExceededQty)?.title} has
                exceeded by{' '}
                {Number(
                  parseFloat(
                    sumForCategory[categoriesWithExceededQty] -
                      maxValueByCategory[categoriesWithExceededQty]
                  ).toFixed(3)
                )}{' '}
                Kg
              </span>
            </td>
          </tr>
        </tfoot>
      ) : isEdit && isProgress && categoriesWithPendingQty ? (
        <tfoot>
          <tr>
            <td colSpan={8}>
              <span className="error-style" style={{ fontSize: '1rem', textWrap: 'nowrap' }}>
                The quantity for{' '}
                {browseNodes?.find((data) => data?.id === categoriesWithPendingQty)?.title} has
                fallen short by{' '}
                {Number(
                  parseFloat(
                    maxValueByCategory[categoriesWithPendingQty] -
                      sumForCategory[categoriesWithPendingQty]
                  ).toFixed(3)
                )}{' '}
                Kg
              </span>
            </td>
          </tr>
        </tfoot>
      ) : isEdit && !isValid ? (
        <tfoot>
          <tr>
            <td colSpan={8}>
              <span className="error-style" style={{ fontSize: '1rem', textWrap: 'nowrap' }}>
                Two Line-Items cannot have the same values for Material category, Material Type,
                State, District and Credit Type simultaneously
              </span>
            </td>
          </tr>
        </tfoot>
      ) : null}
    </Table>
  );
}

export default BrandWorkOrderItemsEdit;
