import React from 'react';

function CloseIcon({ strokeColor = '#005DFF', fill = 'transparent' }) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 5L5 15M5 5L15 15"
        stroke={strokeColor ? strokeColor : '#005DFF'}
        fill={fill ? fill : 'transparent'}
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default CloseIcon;
