import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import SearchIcon from '../../assets/img/search-icon-grey.svg';
import { DropdownSelect } from '../../shared/Components';
import CustomDateRangePicker from '../../shared/Components/CustomDateRangePicker';
import TooltipOnHover from '../../shared/Components/TooltipOnHover';
import { WO_STATUSES } from '../../shared/Constants/Constants';
import { toLocalDate } from '../../shared/Utils/Helper';
import './WorkOrder.scss';

function renderColumn(param, filterValue, setFilter, clients, account, vertical) {
  switch (param) {
    case 'id':
      return (
        <InputGroup>
          <Form.Control
            value={filterValue || ''}
            onChange={(e) => setFilter(e.target.value)}
            placeholder="Search"
            className="column-filter-input"
          />
          <InputGroup.Text className="column-filter-input-icon">
            <img src={SearchIcon} width="15px" height="15px" alt="search" />
          </InputGroup.Text>
        </InputGroup>
      );
    case 'fromCustomerId':
      return (
        <DropdownSelect
          options={clients}
          getOptionLabel={(option) => `${option?.name}`}
          getOptionValue={(option) => `${option?.id}`}
          noOptionsMessage={() => 'Options not found!'}
          onChange={(e) => {
            setFilter(e?.id);
          }}
          value={filterValue || ''}
          className="column-filter-dropdown"
          placeholder="Select"
          isClearable={true}
        />
      );
    case 'poNumber':
      return (
        <InputGroup>
          <Form.Control
            value={filterValue || ''}
            onChange={(e) => setFilter(e.target.value)}
            placeholder="Search"
            className="column-filter-input"
          />
          <InputGroup.Text className="column-filter-input-icon">
            <img src={SearchIcon} width="15px" height="15px" alt="search" />
          </InputGroup.Text>
        </InputGroup>
      );
    case 'createdOn':
      return filterValue?.[0] && filterValue?.[1] ? (
        <>
          <TooltipOnHover
            dataStyle={{ color: '#005dff', cursor: 'pointer' }}
            msg={`${toLocalDate(filterValue[0])} to ${toLocalDate(filterValue[1])}`}>
            <CustomDateRangePicker
              parentEl="#orderDate"
              id="orderDate"
              initialSettings={{
                autoUpdateInput: false,
                locale: {
                  cancelLabel: 'Clear',
                },
                maxDate: new Date(),
                alwaysShowCalendars: false,
              }}
              startDate={filterValue ? filterValue[0] : ''}
              endDate={filterValue ? filterValue[1] : ''}
              placeholder="Select"
              onApply={({ startDate, endDate }, $event) => {
                setFilter([startDate, endDate]);
              }}
              onCancel={(event, picker) => {
                picker.element.val('');
                setFilter(undefined);
              }}
              setFilter={setFilter}
              inputClass="custom-datepicker"
            />
          </TooltipOnHover>
        </>
      ) : (
        <>
          <CustomDateRangePicker
            parentEl="#orderDate"
            id="orderDate"
            initialSettings={{
              autoUpdateInput: false,
              locale: {
                cancelLabel: 'Clear',
              },
              maxDate: new Date(),
              alwaysShowCalendars: false,
            }}
            startDate={filterValue ? filterValue[0] : ''}
            endDate={filterValue ? filterValue[1] : ''}
            placeholder="Select"
            onApply={({ startDate, endDate }, $event) => {
              setFilter([startDate, endDate]);
            }}
            onCancel={(event, picker) => {
              picker.element.val('');
              setFilter(undefined);
            }}
            setFilter={setFilter}
            inputClass="custom-datepicker"
          />
        </>
      );
    case 'toDate':
      return filterValue?.[0] && filterValue?.[1] ? (
        <>
          <TooltipOnHover
            dataStyle={{ color: '#005dff', cursor: 'pointer' }}
            msg={`${toLocalDate(filterValue[0])} to ${toLocalDate(filterValue[1])}`}>
            <CustomDateRangePicker
              parentEl="#orderDate"
              id="orderDate"
              initialSettings={{
                autoUpdateInput: false,
                locale: {
                  cancelLabel: 'Clear',
                },
                maxDate: new Date(),
                alwaysShowCalendars: false,
              }}
              startDate={filterValue ? filterValue[0] : ''}
              endDate={filterValue ? filterValue[1] : ''}
              placeholder="Select"
              onApply={({ startDate, endDate }, $event) => {
                setFilter([startDate, endDate]);
              }}
              onCancel={(event, picker) => {
                picker.element.val('');
                setFilter(undefined);
              }}
              setFilter={setFilter}
              inputClass="custom-datepicker"
            />
          </TooltipOnHover>
        </>
      ) : (
        <>
          <CustomDateRangePicker
            parentEl="#orderDate"
            id="orderDate"
            initialSettings={{
              autoUpdateInput: false,
              locale: {
                cancelLabel: 'Clear',
              },
              maxDate: new Date(),
              alwaysShowCalendars: false,
            }}
            startDate={filterValue ? filterValue[0] : ''}
            endDate={filterValue ? filterValue[1] : ''}
            placeholder="Select"
            onApply={({ startDate, endDate }, $event) => {
              setFilter([startDate, endDate]);
            }}
            onCancel={(event, picker) => {
              picker.element.val('');
              setFilter(undefined);
            }}
            setFilter={setFilter}
            inputClass="custom-datepicker"
          />
        </>
      );
    case 'fulfillmentYear':
      return (
        <DropdownSelect
          options={account?.financialYears}
          getOptionLabel={(option) => `${option?.label}`}
          getOptionValue={(option) => `${option?.value}`}
          noOptionsMessage={() => 'Options not found!'}
          onChange={(e) => {
            setFilter(e?.value);
          }}
          value={filterValue || ''}
          className="column-filter-dropdown"
          placeholder="Select"
          isClearable={true}
        />
      );
    case 'status':
      return (
        <DropdownSelect
          options={
            vertical !== 'PLASTIC'
              ? WO_STATUSES.filter((status) =>
                  ['IN_PROGRESS', 'CLOSED', 'DRAFT'].includes(status?.id)
                )
              : WO_STATUSES.filter((status) => status?.id !== 'APPROVED')
          }
          getOptionLabel={(option) => `${option?.label}`}
          getOptionValue={(option) => `${option?.value}`}
          noOptionsMessage={() => 'Options not found!'}
          onChange={(e) => {
            setFilter(e?.value);
          }}
          value={filterValue || ''}
          className="column-filter-dropdown"
          placeholder="Select"
          isClearable={true}
        />
      );
    case 'poDate':
      return filterValue?.[0] && filterValue?.[1] ? (
        <>
          <TooltipOnHover
            dataStyle={{ color: '#005dff', cursor: 'pointer' }}
            msg={`${toLocalDate(filterValue[0])} to ${toLocalDate(filterValue[1])}`}>
            <CustomDateRangePicker
              parentEl="#orderDate"
              id="orderDate"
              initialSettings={{
                autoUpdateInput: false,
                locale: {
                  cancelLabel: 'Clear',
                },
                maxDate: new Date(),
                alwaysShowCalendars: false,
              }}
              startDate={filterValue ? filterValue[0] : ''}
              endDate={filterValue ? filterValue[1] : ''}
              placeholder="Select"
              onApply={({ startDate, endDate }, $event) => {
                setFilter([startDate, endDate]);
              }}
              onCancel={(event, picker) => {
                picker.element.val('');
                setFilter(undefined);
              }}
              setFilter={setFilter}
              inputClass="custom-datepicker"
            />
          </TooltipOnHover>
        </>
      ) : (
        <>
          <CustomDateRangePicker
            parentEl="#orderDate"
            id="orderDate"
            initialSettings={{
              autoUpdateInput: false,
              locale: {
                cancelLabel: 'Clear',
              },
              maxDate: new Date(),
              alwaysShowCalendars: false,
            }}
            startDate={filterValue ? filterValue[0] : ''}
            endDate={filterValue ? filterValue[1] : ''}
            placeholder="Select"
            onApply={({ startDate, endDate }, $event) => {
              setFilter([startDate, endDate]);
            }}
            onCancel={(event, picker) => {
              picker.element.val('');
              setFilter(undefined);
            }}
            setFilter={setFilter}
            inputClass="custom-datepicker"
          />
        </>
      );
    default:
      return;
  }
}

function ColumnFilters({ column }) {
  const { filterValue, setFilter, vertical } = column;
  const { clients } = useSelector(({ account }) => ({
    clients: account.clients,
  }));
  const account = useSelector(({ account }) => account);
  return (
    <span>{renderColumn(column?.id, filterValue, setFilter, clients, account, vertical)}</span>
  );
}

export default ColumnFilters;
