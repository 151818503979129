import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './WorkOrderItemsEdit.scss';
import { isEmpty } from 'lodash';

const PromptInputModal = ({
  open,
  onHide,
  data,
  setData,
  title = 'Updating Order?',
  heading = 'Specify Reason',
  placeholder = 'Write the reason here',
  buttonText = 'Save Changes',
  onSubmit,
  danger = false,
}) => {
  const handleChangeInput = (e) => {
    let value = e.target.value;
    setData(value);
  };
  return (
    <Modal show={open} onHide={onHide} centered size="lg">
      <Modal.Header className="editsavedmodalheader" closeButton>
        {' '}
        {title}
      </Modal.Header>
      <Modal.Body>
        <h3 className="editsavedmodalreason mb-2">{heading}</h3>
        <textarea
          className="editsavedmodalinput"
          value={data}
          maxLength={600}
          onChange={handleChangeInput}
          placeholder={placeholder}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline"
          className={danger ? 'closewomodalbutton' : 'editsavedmodalbutton'}
          disabled={!(data && data?.length > 0)}
          onClick={onSubmit}>
          {buttonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PromptInputModal;
