import * as axios from 'axios';
import API from '../../shared/Config/Api';
import APIEndpoints from '../../shared/Config/ApiEndpoints.const';

/**
 * Api call to get GSTIN by pan number
 * @param pan
 * @param successCallback
 * @param failureCallback
 */
export const getGSTINsByPan = (pan, successCallback, failureCallback) => {
  API.get(APIEndpoints.GET_GSTIN_BY_PAN + pan).then(
    (response) => {
      if (response.status === 200) {
        successCallback(response);
      }
    },
    (error) => {
      failureCallback(error);
    }
  );
};

/**
 * Api call to get Signed URL
 * @param fileDetails
 * @param successCallback
 * @param failureCallback
 */
export const getSignedURL = (fileDetails, successCallback, failureCallback) => {
  API.post(APIEndpoints.STAKEHOLDERS_SIGNED_URL, fileDetails)
    .then(
      (response) => {
        if (response.status === 200) {
          successCallback(response);
        }
      },
      (error) => {
        console.log(error);
        failureCallback(error);
      }
    )
    .catch((error) => {
      console.log(error);
      failureCallback(error);
    });
};

/**
 * Upload file to S3 bucket
 * @param fileDetails
 * @param successCallback
 * @param failureCallback
 */
export const uploadFileToS3 = (
  { signedURL, file, fileType },
  signedURLResponse,
  successCallback,
  failureCallback,
  progressCallback
) => {
  const instance = axios.create({
    headers: { 'Content-Type': fileType },
    timeout: 60000,
    onUploadProgress: progressCallback,
  });
  delete instance.defaults.headers.common.Authorization;

  instance
    .put(signedURL, file)
    .then((response) => {
      if (response.status === 200) {
        successCallback(signedURLResponse);
      }
    })
    .catch((error) => {
      failureCallback(error);
    });
};

/**
 *
 * @param fileDetails
 * @param successCallback
 * @param failureCallback
 */
export const uploadDocument = (
  fileDetails,
  file,
  successCallback,
  failureCallback,
  progressCallback
) => {
  getSignedURL(
    fileDetails,
    (signedURLResponse) => {
      const s3Details = {
        signedURL: signedURLResponse.data.signedUrl,
        file: file,
        fileType: file.type,
      };
      uploadFileToS3(
        s3Details,
        signedURLResponse,
        successCallback,
        failureCallback,
        progressCallback
      );
    },
    failureCallback
  );
};

/**
 *
 * @param serviceProviderID
 * @param documentID
 * @param successCallback
 * @param failureCallback
 */
export const deleteDocument = (serviceProviderID, documentID, successCallback, failureCallback) => {
  API.delete(APIEndpoints.SERVICE_PROVIDER + '/agreement-documents', {
    serviceProviderId: serviceProviderID,
    documentId: documentID,
  }).then(
    (response) => {
      if (response.status === 200) {
        successCallback(response);
      }
    },
    (error) => {
      failureCallback(error);
    }
  );
};

/**
 * Api call to create a Service Provider
 * @param serviceProviderDetails
 * @param successCallback
 * @param failureCallback
 */
export const createServiceProvider = (serviceProviderDetails, successCallback, failureCallback) => {
  API.post(APIEndpoints.SERVICE_PROVIDER, serviceProviderDetails).then(
    (response) => {
      if (response.status === 200) {
        successCallback(response);
      }
    },
    (error) => {
      failureCallback(error);
    }
  );
};

/**
 * Api call to get all Service Providers
 * @param successCallback
 * @param failureCallback
 */
export const getAllServiceProviders = (successCallback, failureCallback) => {
  API.get(APIEndpoints.SERVICE_PROVIDER).then(
    (response) => {
      if (response.status === 200) {
        successCallback(response);
      }
    },
    (error) => {
      failureCallback(error);
    }
  );
};

/**
 * Api call to get Service Providers by filter, sort and pagination
 * @param successCallback
 * @param failureCallback
 */
export const getFilteredServiceProviders = (
  filterString,
  dateString,
  successCallback,
  failureCallback
) => {
  API.get(APIEndpoints.FILTERED_SERVICE_PROVIDERS + filterString + dateString).then(
    (response) => {
      if (response.status === 200) {
        successCallback(response);
      }
    },
    (error) => {
      failureCallback(error);
    }
  );
};

/**
 * Api call to get Service Providers by filter, sort and pagination
 * @param successCallback
 * @param failureCallback
 */
export const getFilteredClients = (filterString, dateString, successCallback, failureCallback) => {
  API.get(APIEndpoints.FILTERED_CLIENT_SERVICE_PROVIDERS + filterString + dateString).then(
    (response) => {
      if (response.status === 200) {
        successCallback(response);
      }
    },
    (error) => {
      failureCallback(error);
    }
  );
};

/**
 * Api call to get all Service Providers
 * @param serviceProviderID
 * @param successCallback
 * @param failureCallback
 */
export const getServiceProvider = (serviceProviderID, successCallback, failureCallback) => {
  API.get(APIEndpoints.SERVICE_PROVIDER + '/' + serviceProviderID).then(
    (response) => {
      if (response.status === 200) {
        successCallback(response);
      }
    },
    (error) => {
      failureCallback(error);
    }
  );
};

export const updateKamDetails = async (customerId, kamId, zone) => {
  return await API.post(
    APIEndpoints.CUSTOMER_KAM_UPDATE + `?customerId=${customerId}&kamId=${kamId}&zone=${zone}`
  );
};

export const updateClientDetails = async (payload) => {
  return await API.put(APIEndpoints.UPDATE_CLIENT, payload);
};

export const updateClientContactDetails = async (payload) => {
  return await API.put(APIEndpoints.UPDATE_CLIENT_CONTACT_INFO, payload);
};

export const updateClientKycDocuments = async (payload) => {
  return await API.put(APIEndpoints.UPDATE_CLIENT_KYC_DOCUMENTS, payload);
};

export const deleteClientKycDocument = async (id) => {
  return await API.delete(APIEndpoints.DELETE_CLIENT_KYC_DOCUMENT + '/' + id);
};

/**
 * API call to update aggreement details on service provider
 */

export const updateAgreementDetails = (agreementDetails, successCallback, failureCallback) => {
  API.put(APIEndpoints.SERVICE_PROVIDER + '/agreement', agreementDetails).then(
    (response) => {
      if (response.status === 200) {
        successCallback(response);
      }
    },
    (error) => {
      failureCallback(error);
    }
  );
};

/**
 * API call to update contact details on service provider
 */
export const updateContactDetails = (contactDetails, successCallback, failureCallback) => {
  API.put(APIEndpoints.SERVICE_PROVIDER + '/contact-person', contactDetails).then(
    (response) => {
      if (response.status === 200) {
        successCallback(response);
      }
    },
    (error) => {
      failureCallback(error);
    }
  );
};

/**
 * API call to update info details on service provider
 */
export const updateInfoDetails = (infoDetails, successCallback, failureCallback) => {
  API.put(APIEndpoints.SERVICE_PROVIDER + '/info', infoDetails).then(
    (response) => {
      if (response.status === 200) {
        successCallback(response);
      }
    },
    (error) => {
      failureCallback(error);
    }
  );
};

/**
 * API call to get all filter options on the service providers
 */
export const getFacets = (successCallback, failureCallback) => {
  API.get(APIEndpoints.WORK_ORDERS_FILTER_FACETS, { timeout: 30000 }).then(
    (response) => {
      if (response.status === 200) {
        successCallback(response);
      }
    },
    (error) => {
      failureCallback(error);
    }
  );
};

/**
 * Api call to get all Service Providers
 * @param serviceProviderID
 * @param successCallback
 * @param failureCallback
 */
export const addBrandInvoiceType = async (payload) => {
  try {
    const response = await API.post(APIEndpoints.BRAND_INVOICE_TYPE_SAVE, payload);
    if (response?.status === 201) return Promise.resolve(response);
    else return Promise.resolve({ status: response });
  } catch (error) {
    console.log('addBrandInvoiceType', error);
  }
};

export const editBrandInvoiceType = async (payload) => {
  try {
    const response = await API.put(APIEndpoints.BRAND_INVOICE_TYPE_UPDATE, payload);
    if (response?.status === 200) return Promise.resolve(response);
    else return Promise.resolve({ status: response });
  } catch (error) {
    console.log('editBrandInvoiceType', error);
  }
};

export const getBrandInvoiceType = async (payload) => {
  try {
    const response = await API.get(APIEndpoints.BRAND_INVOICE_TYPE_GET + payload);
    if (response?.status === 200) return Promise.resolve(response?.data);
    else return Promise.resolve(401);
  } catch (error) {
    console.log('getBrandInvoiceType', error);
  }
};

export const fetchVendorFulfillmentDetails = async (params) => {
  return await API.get(APIEndpoints.VENDOR_FULFILLMENT_DETAILS, null, params);
};

export const saveVendorFulfillmentDetails = async (payload) => {
  return await API.post(APIEndpoints.SAVE_VENDOR_FULFILLMENT_DETAILS, payload);
};

export const updateVendorFulfillmentDetails = async (payload) => {
  return await API.put(APIEndpoints.UPDATE_VENDOR_FULFILLMENT_DETAILS, payload);
};

export const fetchDeliverablesList = async () => {
  return await API.get(APIEndpoints.FETCH_DELIVERABLES_LIST);
};

export const fetchSelectedDeliverablesList = async (params) => {
  return await API.get(APIEndpoints.FETCH_SELECTED_DELIVERABLES_LIST + params);
};

export const saveDeliverablesList = async (payload) => {
  return await API.post(APIEndpoints.SAVE_VENDOR_DELIVERABLES, payload);
};

export const fetchRevisionLog = async (params) => {
  return await API.get(APIEndpoints.FETCH_REVISION_LOG + params);
};

export const saveVendorAddendum = async (payload) => {
  return await API.post(APIEndpoints.SAVE_VENDOR_ADDENDUM, payload);
};

export const deleteSelectedDeliverables = async (payload) => {
  return await API.delete(APIEndpoints.DELETE_SELECTED_DELIVERABLES, payload);
};

export const fulfillmentCategoryWise = async (payload) => {
  return await API.post(APIEndpoints.FULFILLMENT_CATEGORY_WISE, payload);
};

export const fetchCustomerAddress = async (params) => {
  return await API.get(APIEndpoints.CUSTOMER_ADDRESS, null, params);
};

export const getClientsById = (serviceProviderID, successCallback, failureCallback) => {
  API.get(APIEndpoints.CLIENT_DETAILS + '/' + serviceProviderID).then(
    (response) => {
      if (response.status === 200) {
        successCallback(response);
      }
    },
    (error) => {
      failureCallback(error);
    }
  );
};
