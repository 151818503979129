import React from 'react';

const ArrowUpRight = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M5.83398 14.1663L14.1673 5.83301M14.1673 5.83301H5.83398M14.1673 5.83301V14.1663"
        stroke="#004EEB"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ArrowUpRight;
