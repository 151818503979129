import { detailedDiff } from 'deep-object-diff';
import { get, isEmpty, mapKeys, mapValues, omit, zip } from 'lodash';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useRegionsMap } from '../../../hooks';
import { toCapitalize, toLocalDate } from '../../../shared/Utils/Helper';

function flattenObj(obj, parent, res = {}) {
  for (let key in obj) {
    let propName = parent ? parent + '.' + key : key;
    if (typeof obj[key] == 'object') {
      flattenObj(obj[key], propName, res);
    } else {
      res[propName] = obj[key];
    }
  }
  return res;
}

function WorkOrderChangeLog({ logs, userList, browseNodes, workOrder, showModal, handleClose }) {
  const { clients, serviceProviders } = useSelector(({ account, serviceProvider }) => ({
    clients: account.clients,
    serviceProviders: serviceProvider.listLight,
  }));

  let regionsMap = useRegionsMap();
  if (!logs?.length) return <></>;

  const diffs = zip([null, ...logs], logs)
    .filter(([prev, current]) => !!prev && !!current)
    .map(([prev, current]) => {
      let prevObj = prev && JSON.parse(prev.json);
      let currentObj = JSON.parse(current.json);

      [prevObj, currentObj] = [prevObj, currentObj].map((wo) => {
        return (
          wo && {
            'Service Provider':
              serviceProviders.find((e) => e.id === Number(wo?.toCustomerId))?.name || 'N/A',
            'Start Date': toLocalDate(wo?.fromDate) || 'N/A',
            'Estimated Delivery Date': toLocalDate(wo.toDate) || 'N/A',
            Status: toCapitalize(wo.status),
            Item: wo.workOrderItems.map((woi) => {
              return {
                Material: woi?.itemName || 'N/A',
                State: regionsMap[woi?.stateId]?.name || 'N/A',
                District: regionsMap[woi?.stateId]?.districts[woi?.districtId]?.name || 'N/A',
                Target: woi?.qty || 'N/A',
                Rate: woi?.pricePerUnit || 'N/A',
                'Fulfilment Cycle': toCapitalize(woi?.fulfillmentCycle) || 'N/A',
                // 'Delivery Date': toLocalDate(woi?.estimatedDelivery) || 'N/A',
                'fulfilment plan':
                  (!isEmpty(woi?.fulfilmentPlan) &&
                    mapKeys(woi?.fulfilmentPlan, function (value, key) {
                      return key ? toLocalDate(key) : '';
                    })) ||
                  'N/A',
              };
            }),
            'PO Number': wo?.poNumber || 'N/A',
            'Entity Type': wo?.entityType || 'N/A',
            'Customer Type': wo?.customerType || 'N/A',
            'Payment Term': wo?.paymentTerm || 'N/A',
            'Traceability Documents': wo?.traceabilityDocuments === true ? 'Yes' : 'No',

            'Fulfillment Year':
              `${wo?.fulfillmentYearStartDate?.split('-')[0]} - ${
                wo?.fulfillmentYearEndDate?.split('-')[0]
              }` || 'N/A',
            'Extra comments': wo?.extraComments || 'N/A',
            Comments: wo?.comments || 'N/A',
          }
        );
      });

      let diff = prevObj && detailedDiff(currentObj, prevObj);
      if (diff) {
        diff.updated = mapValues(flattenObj(diff.updated), (v, k) => {
          return `${get(currentObj, k)} => ${v}`;
        });
        diff.deleted = Object.keys(flattenObj(diff.deleted));
      }
      return {
        ...omit(prev, 'json'),
        diff,
      };
    });

  const returnStatusAction = (data) => {
    if (data && data.previousValue && data.previousValue.length > 0) {
      let hasStatus = false;
      let hasOtherKeys = false;

      data.previousValue.forEach((item) => {
        if (item.key === 'Status') {
          hasStatus = true;
        } else if (item.value && item.value.length > 0) {
          hasOtherKeys = true;
        }
      });
      if (!hasStatus && hasOtherKeys) {
        return 'ShowEdit';
      } else if (hasStatus && hasOtherKeys) {
        return 'ShowEditAndStatus';
      } else {
        return 'ShowStatus';
      }
    }

    return 'ShowStatus';
  };

  const displayDifference = (diffObj) => {
    const changes = {
      previousValue: [],
      currentValue: [],
    };

    if (diffObj?.updated && Object.getOwnPropertyNames(diffObj?.updated).length > 0) {
      Object.keys(diffObj?.updated).forEach((key) => {
        const [previousValue, currentValue] = diffObj.updated[key]?.split(' => ');
        changes.previousValue.push({ key, value: previousValue });
        changes.currentValue.push({ key, value: currentValue });
      });
    }

    if (diffObj?.added && Object.getOwnPropertyNames(diffObj?.added).length > 0) {
      Object.keys(diffObj?.added).forEach((key) => {
        changes.previousValue.push({ key, value: 'NA' });
        changes.currentValue.push({ key, value: JSON.stringify(diffObj.added[key], null, 2) });
      });
    }

    if (diffObj?.deleted && diffObj?.deleted.length > 0) {
      diffObj.deleted.forEach((key) => {
        changes.previousValue.push({ key, value: JSON.stringify(diffObj.deleted[key], null, 2) });
        changes.currentValue.push({ key, value: 'NA' });
      });
    }

    return changes;
  };

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      className="work-order-log-modal"
      size={'xl'}
      centered>
      <Modal.Header closeButton>
        <Modal.Title>Audit Trail</Modal.Title>
      </Modal.Header>

      <Modal.Body className="work-order-log">
        {!isEmpty(logs) && (
          <div className="table-wrapper">
            <table
              border={1}
              cellPadding={10}
              className="w-100 work-order-log-table table-responsive-sm table-responsive-md">
              <thead className="thead_logs">
                <tr>
                  <td>User & Timestamp</td>
                  <td>Action / Status update</td>
                  <td>Previous Value</td>
                  <td>Updated Value/ Comments</td>
                </tr>
              </thead>
              <tbody>
                {[...diffs, logs[logs.length - 1]].map((change, id) => {
                  const diffData = change?.diff
                    ? displayDifference(change.diff)
                    : { previousValue: [], currentValue: [] };

                  const showActionStatus = returnStatusAction(diffData);

                  const statusText =
                    change?.status === 'IN_PROGRESS'
                      ? toCapitalize('APPROVED')
                      : toCapitalize(change.status);

                  return (
                    <tr key={`${change?.changedBy}${id}`}>
                      <td>
                        <p className="mb-3 text_value_action_user">
                          {userList?.some((user) => user?.id === change?.changedBy)
                            ? userList?.find((user) => user?.id === change?.changedBy)?.name
                            : serviceProviders?.some((user) => user?.id === change?.customerId)
                            ? serviceProviders?.find((user) => user?.id === change?.customerId)
                                ?.name
                            : clients?.find((user) => user?.id === change?.customerId)?.name ||
                              change?.changedByUserName}{' '}
                        </p>
                        <p className="text_value_action_user">
                          {toLocalDate(change.changedOn, 'DD/MM/YYYY h:mm a') || 'N/A'}
                        </p>
                      </td>

                      <td className="text_value_action_user">
                        {showActionStatus
                          ? showActionStatus === 'ShowEdit'
                            ? 'Edit '
                            : showActionStatus === 'ShowEditAndStatus' &&
                              change?.status !== 'CLOSED' &&
                              change?.status !== 'REJECTED'
                            ? `Edit / ${statusText}`
                            : `${statusText}`
                          : `${statusText}`}{' '}
                      </td>
                      <td>
                        {diffData.previousValue.map((item, i) => {
                          if (!['CLOSED', 'REJECTED'].includes(change?.status)) {
                            return (
                              <p key={i} className="audit_log_text_value">
                                {item.key}:{' '}
                                <span
                                  className="audit_log_prev-value"
                                  style={item?.value?.length >= 100 ? { height: '100px' } : {}}>
                                  {item.value ? item.value : 'N/A'}
                                </span>
                              </p>
                            );
                          }
                          return null;
                        })}
                      </td>

                      <td>
                        {diffData.currentValue.map((item, i) => {
                          if (!['CLOSED', 'REJECTED'].includes(change?.status)) {
                            return (
                              <p key={i} className="audit_log_text_value">
                                {item.key}:{' '}
                                <span
                                  className="audit_log_prev-value"
                                  style={item?.value?.length >= 100 ? { height: '100px' } : {}}>
                                  {item.value ? item.value : 'N/A'}
                                </span>
                              </p>
                            );
                          }
                          return null;
                        })}

                        {change?.remark && (
                          <span className="audit_log_text_value">
                            Reason:
                            <small
                              style={change?.remark?.length >= 100 ? { height: '100px' } : {}}
                              className="audit_log_remarks">
                              {change?.remark}
                            </small>
                          </span>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default WorkOrderChangeLog;
