import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemSecondaryAction,
  Typography,
  Link,
  ListItemIcon,
  Paper,
  Button,
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { faComments } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as PickUpIcon } from '../../../../assets/img/_App/picksv.svg';
import { ReactComponent as DropIcon } from '../../../../assets/img/_App/dropsv.svg';
import { ReactComponent as TruckIcon } from '../../../../assets/img/_App/transicon.svg';
import { makeStyles } from '@material-ui/core/styles';
import {
  DELIVERY_STATUS,
  PERMISSIONS_GROUP_NAMES,
  PERMISSIONS_MODULE_NAME,
  PERMISSIONS_NAME,
} from '../../../../shared/Constants/Constants';
import { toast } from 'react-toastify';
import HasAuthority from '../../../../shared/Components/HasAuthority/HasAuthorityNew';

const useStyles = makeStyles((theme) => ({
  alignRight: {
    textAlign: 'end',
  },
  mainHead: {
    color: '#2e384d',
    fontFamily: 'font-semibold',
  },
  linkStyl: {
    color: '#33ab2e',
  },
  small: {
    color: '#fff',
    backgroundColor: '#33ab2e',
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  sendSmsButton: {
    borderRadius: '25px',
    backgroundColor: '#33ab2e',
    fontWeight: 'bold',
    height: '32px',
    color: '#fff',
  },
  inlineflex: {
    display: 'inline-flex',
  },
}));
const SupervisorCard = (props) => {
  const classes = useStyles();
  const {
    data,
    showEditChange,
    isEditable,
    displayMyself,
    user,
    isPRO,
    doSendSMSToPickUpSupervisor,
    doSendSMSToDropSupervisor,
    assignedDeliveryStatus,
    sendSMS,
  } = { ...props };
  const showSendSMS =
    !isPRO &&
    sendSMS &&
    (assignedDeliveryStatus !== DELIVERY_STATUS.APPROVED.id ||
      assignedDeliveryStatus !== DELIVERY_STATUS.DISPUTED.id);

  const sendSMSTOPICKUP = () => {
    if (data?.pickUpSupervisorMobile) doSendSMSToPickUpSupervisor();
    else toast.warn('Pickup supervisor mobile number is missing');
  };

  const sendSMSTODROP = () => {
    if (data?.dropSupervisorMobile) doSendSMSToDropSupervisor();
    else toast.warn('Drop supervisor mobile number is missing');
  };
  return (
    <Paper elevation={0} className="mt-3">
      <List>
        <ListItem>
          <ListItemText
            primary={
              <Typography
                className={`${classes.mainHead} mb-2`}
                variant="body1"
                color="textPrimary">
                Supervisors
              </Typography>
            }
          />
          {!isPRO && (
            <ListItemSecondaryAction>
              {isEditable && (
                <ListItemText
                  primary={
                    <Typography className={`${classes.alignRight} mb-2`}>
                      <Link style={{ color: '#33ab2e' }} onClick={showEditChange}>
                        Change
                      </Link>
                    </Typography>
                  }
                />
              )}
            </ListItemSecondaryAction>
          )}
        </ListItem>
        <Divider />

        <ListItem>
          <ListItemIcon className={classes.justifyCenter}>
            <PickUpIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <>
                <Typography color="textSecondary">Pickup Supervisor</Typography>
                <Typography className="mb-2" variant="body1" color="textPrimary">
                  {displayMyself(
                    user.mobile,
                    data?.pickUpSupervisorMobile,
                    data?.pickUpSupervisorName
                  ) || 'NA'}
                </Typography>
              </>
            }
          />
          <ListItemSecondaryAction className={classes.inlineflex}>
            {/* <Avatar className={classes.small}>
              <FontAwesomeIcon className={''} icon={faComments} size="sm" />
            </Avatar>
            <Avatar className={`${classes.small} ml-2`}>
              <FontAwesomeIcon className={''} icon={faPhoneAlt} size="sm" />
            </Avatar> */}
            {showSendSMS && (
              <Button
                variant="contained"
                className={`${classes.sendSmsButton} ml-2`}
                onClick={sendSMSTOPICKUP}>
                Send SMS
              </Button>
            )}
          </ListItemSecondaryAction>
        </ListItem>
        <Divider variant="inset" component="li" />

        <ListItem>
          <ListItemIcon className={classes.justifyCenter}>
            <DropIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <>
                <Typography color="textSecondary">Delivery Supervisor</Typography>
                <Typography className="mb-2" variant="body1" color="textPrimary">
                  {displayMyself(
                    user.mobile,
                    data?.dropSupervisorMobile,
                    data?.dropSupervisorName
                  ) || 'NA'}
                </Typography>
              </>
            }
          />
          <ListItemSecondaryAction className={classes.inlineflex}>
            {/* <Avatar className={classes.small}>
              <FontAwesomeIcon className={''} icon={faComments} size="sm" />
            </Avatar>
            <Avatar className={`${classes.small} ml-2`}>
              <FontAwesomeIcon className={''} icon={faPhoneAlt} size="sm" />
            </Avatar> */}
            {showSendSMS && (
              <HasAuthority
                permissionsAllowed={[
                  {
                    groupName: PERMISSIONS_GROUP_NAMES.DELIVERY,
                    moduleName: PERMISSIONS_MODULE_NAME.DELIVERY,
                    name: PERMISSIONS_NAME.SEND_SMS_E_WASTE,
                  },
                ]}>
                <Button
                  variant="contained"
                  className={`${classes.sendSmsButton} ml-2`}
                  onClick={sendSMSTODROP}>
                  Send SMS
                </Button>
              </HasAuthority>
            )}
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </Paper>
  );
};

export default SupervisorCard;
