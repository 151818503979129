/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { Badge, Button, Card, Col, FormControl, Image, Row } from 'react-bootstrap';
import * as moment from 'moment';
import './DocumentCard.scss';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import uploadIcon from '../../../../assets/img/upload.png';
import deleteIcon from '../../../../assets/img/delete.svg';
import WarningIcon from '../../../../assets/img/ic_warning.svg';
import {
  DOCUMENT_STATUSES,
  PERMISSIONS_GROUP_NAMES,
  PERMISSIONS_MODULE_NAME,
  PERMISSIONS_NAME,
} from '../../../../shared/Constants/Constants';
import { OverlayCell } from '../../../../shared/Components/GenericComponent';
import { Loader } from '../../../../shared/Components';
import { DeliveryService } from '../../../../features/Delivery/delivery.service';
import { subscribe } from '../../../../shared/Events/AppEvents';
import { RcylDatePicker } from '../../../../shared/Components';
import { toLocalDate } from '../../../../shared/Utils/Helper';
import DocumentModal from '../DocumentModal/DocumentModal';
import HasAuthority, {
  useAuthority,
} from '../../../../shared/Components/HasAuthority/HasAuthorityNew';

const DocumentCard = (props) => {
  const {
    deliveryStatus,
    onFileSelected,
    deliveryId,
    mainDeliveryID,
    onDocTypeChange,
    docTypeList,
    invoiceEntity,
    selectedContainer,
    entity,
    typeIndex,
    isHighlight,
    uploadStatus,
    onDelete,
    onCompressedImgSave,
    saleOrderId,
    doFetchDelivery,
    setDelivery,
    isBrand,
    isNonPlastic,
    deliveryDetails,
    isOutDated,
    isPRO,
    documentDeliveryId,
    isServiceProvider,
    carouselHandler,
    groupName,
    moduleName,
    isBrandDelivery,
    isVendorDelivery,
  } = props;
  const [modalVisibility, setModalVisibility] = useState(false);
  const [deliveryDoc, setDeliveryDoc] = useState();
  const [isDocumentPresent, setIsDocumentPresent] = useState(true);
  const [docList, setDocList] = useState([]);
  const { document } = deliveryDoc || {};
  let disabled =
    deliveryDoc?.status === DOCUMENT_STATUSES.APPROVED ||
    isBrand ||
    deliveryDoc?.status === DOCUMENT_STATUSES.REJECTED;
  const isRootDoc = !props.document?.fromCustomerId;
  const { hasPermission } = useAuthority();
  const canReTag = hasPermission({
    permissionsAllowed: [
      {
        groupName: PERMISSIONS_GROUP_NAMES.DELIVERY,
        moduleName:
          isPRO && isBrandDelivery
            ? PERMISSIONS_MODULE_NAME.BRAND_DELIVERY
            : isPRO && isVendorDelivery
            ? PERMISSIONS_MODULE_NAME.VENDOR_DELIVERY
            : PERMISSIONS_MODULE_NAME.DELIVERY,
        name: PERMISSIONS_NAME.RE_TAG_DOCUMENT,
      },
    ],
  });

  const isShowActionForMpDelivery = isNonPlastic
    ? true
    : deliveryStatus === 'IN_PROGRESS'
    ? false
    : true;

  disabled = !isEmpty(props?.invoiceEntity) ? disabled : !isRootDoc && disabled;

  /**
   * Hooks
   */
  useEffect(() => {
    setIsDocumentPresent(!!(props.document && props.document.document));
    subscribe(`DELIVERY_DOC_${props.document?.id}`, (event, payload) => {
      if (event === 'OPEN_MODAL') {
        setModalVisibility(true);
      }
    });
  }, [document]);

  useEffect(() => {
    setDeliveryDoc(props?.document);
  }, [props?.document]);

  /**
   * callback called when file choosen from filemanager
   * @type {(...args: any[]) => any}
   */
  const onDrop = useCallback((acceptedFiles) => {
    onFileSelected(acceptedFiles, props.document?.id, document?.type);
  }, []);

  /**
   * DropZone hooks<DocumentCard
   */
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png, application/pdf',
    maxSize: '10485760',
    multiple: document?.id === undefined,
  });

  /**
   * Handler
   */
  const handleTypeChange = (e) => {
    if (e.target.value !== 'Select name') {
      const { document } = { ...deliveryDoc };
      document.type = e.target.value;
      const newDelDoc = { ...deliveryDoc, document: document };
      setDeliveryDoc(newDelDoc);
      onDocTypeChange(newDelDoc);
    }
  };

  useEffect(() => {
    if (docTypeList !== undefined) {
      setDocList(docTypeList);
    }
  }, [docTypeList]);

  const convertBytesToKb = (val) => {
    return Math.floor(val / 1000);
  };

  const displayAlert = (fileSize) => {
    return convertBytesToKb(fileSize) > 100 ? (
      <OverlayCell overlay={<span>{'file size is > 100 KB'}</span>}>
        <Image width="15px" src={WarningIcon} />
      </OverlayCell>
    ) : (
      ''
    );
  };

  const isCertificateContainer = () =>
    selectedContainer === 'CERTIFICAT' && document?.type !== 'CERTIFICATE';

  const addCertificateDate = async (certificateDate, currentDate) => {
    if (toLocalDate(currentDate) !== toLocalDate(certificateDate)) {
      const response = await DeliveryService.addCertificateDate({
        deliveryDocumentId: documentDeliveryId,
        docType: document?.type,
        certificateDate: certificateDate,
      });
      if (response?.status === 200) {
        doFetchDelivery();
        toast.success('Certificate date added successfully');
      } else toast.error('Failed to add certificate date');
    }
  };

  const isCertificateEdit = () => {
    switch (true) {
      case isPRO:
        return true;
      case isBrand:
        return false;
      case isServiceProvider:
        return isEmpty(document?.certificateDate);
      default:
        return;
    }
  };

  return (
    <React.Suspense fallback={''}>
      <div className="DocumentCard">
        ✓
        <Card className={`mr-2 ${invoiceEntity ? 'grey-bg' : 'white-bg'}`}>
          {document?.viewUrl && (
            <Card.Header className={`p-2 ${invoiceEntity ? 'grey-bg' : 'white-bg'}`}>
              <Row noGutters>
                <Col className="d-flex justify-content-between">
                  {selectedContainer && entity !== 'WORK_ORDER' && (
                    <div className="grid flex-fill">
                      <FormControl
                        className={`float-label w-100 doc-select ${invoiceEntity && 'grey-bg'}`}
                        value={document.type}
                        disabled={disabled || !isShowActionForMpDelivery || !canReTag}
                        onChange={handleTypeChange}
                        as={'select'}>
                        <option>Select name</option>
                        {docList[typeIndex]?.documents.map((docType) => {
                          return (
                            <option key={docType.type} value={docType.type}>
                              {docType.title}
                            </option>
                          );
                        })}
                      </FormControl>
                      <div className="text-small">
                        {!!deliveryDoc?.createdOn &&
                          moment(deliveryDoc?.createdOn).format('DD MMM YYYY hh:mm a')}
                      </div>
                    </div>
                  )}
                  {invoiceEntity !== 'INVOICE_MODE' ? (
                    <div style={{ cursor: 'pointer' }}>
                      {disabled || !isShowActionForMpDelivery || isOutDated ? null : (
                        <HasAuthority
                          permissionsAllowed={[
                            {
                              groupName: groupName,
                              moduleName: moduleName,
                              name: PERMISSIONS_NAME.DELETE_DOCUMENT,
                            },
                          ]}>
                          <Image
                            src={deleteIcon}
                            width="28px"
                            className="px-2"
                            onClick={() => onDelete(deliveryDoc)}
                          />
                        </HasAuthority>
                      )}
                    </div>
                  ) : null}
                </Col>
              </Row>
              {isCertificateContainer() && (
                <Row noGutters>
                  <Col>
                    <RcylDatePicker
                      parentEl="#dropOffDate"
                      singleDatePicker={true}
                      controlId={'fulfillmentDateRange'}
                      placeholder="DD-MM-YYYY"
                      startDate={document?.certificateDate}
                      disabled={!isCertificateEdit()}
                      onApply={({ startDate }) =>
                        isCertificateEdit() &&
                        addCertificateDate(startDate, document?.certificateDate)
                      }
                    />
                  </Col>
                </Row>
              )}
            </Card.Header>
          )}

          <Card.Body
            style={{ cursor: 'pointer' }}
            className={classNames('position-relative', {
              empty: !document?.url,
              active: isHighlight,
            })}>
            {document && document?.viewUrl ? (
              <>
                <div className="approved-rejected">
                  {deliveryDoc.status === 'APPROVED' && !isRootDoc && (
                    <h5>
                      <Badge
                        className="font-regular text-light"
                        style={{ backgroundColor: '#80DECA' }}>
                        Approved
                      </Badge>
                    </h5>
                  )}
                  {(isOutDated || deliveryDoc.status === 'REJECTED') && (
                    <h5>
                      <Badge
                        className="font-light text-light"
                        style={{ backgroundColor: '#F97066' }}>
                        {isOutDated ? 'Outdated' : 'Rejected'}
                      </Badge>
                    </h5>
                  )}
                  {deliveryDoc.status === 'PENDING' && (
                    <h5>
                      <Badge
                        className="font-light text-light"
                        style={{ backgroundColor: '#F7D198' }}>
                        Resolved
                      </Badge>
                    </h5>
                  )}
                  {deliveryDoc.status === 'IN_REVIEW' && (
                    <h5>
                      <Badge
                        className="font-light text-light"
                        style={{ backgroundColor: '#7A96F8' }}>
                        In Review
                      </Badge>
                    </h5>
                  )}
                </div>
                <div
                  className="doc-uploaded"
                  onClick={() => {
                    entity === 'WORK_ORDER' || invoiceEntity === 'INVOICE_MODE'
                      ? window.open(document.viewUrl)
                      : carouselHandler(deliveryDoc);
                  }}>
                  {uploadStatus?.uploading ? (
                    <div className="d-flex flex-column align-items-center justify-content-center h-100">
                      <Loader hideText={true} size="sm" />
                      <p>{uploadStatus?.progress}%</p>
                    </div>
                  ) : document.contentType === 'application/pdf' ? (
                    <object
                      aria-label="pdf document"
                      type="application/pdf"
                      data={document.viewUrl}
                      width="100%"
                      height="100%"
                    />
                  ) : (
                    <img
                      crossOrigin="anonymous"
                      src={document.viewUrl}
                      className="image-view"
                      alt="view_image"
                    />
                  )}
                </div>
              </>
            ) : (
              <div className="no-doc-uploaded" {...getRootProps()}>
                <input {...getInputProps()} />
                <p>
                  <Image width="40px" src={uploadIcon} />
                </p>
                <p className="text-muted">
                  Extensions supported (jpeg, png, pdf) max size 10 Mb per document
                </p>
                <HasAuthority
                  permissionsAllowed={[
                    {
                      groupName: groupName,
                      moduleName: moduleName,
                      name: PERMISSIONS_NAME.UPLOAD_DOCUMENT,
                    },
                  ]}
                  failureComponent={
                    <span className="invalid-feedback" style={{ color: 'red', display: 'block' }}>
                      You don't have permissions to upload documents.
                    </span>
                  }>
                  <Button size={'sm'}>Upload</Button>
                </HasAuthority>
              </div>
            )}
          </Card.Body>
        </Card>
        {isDocumentPresent && (
          <DocumentModal
            show={modalVisibility}
            isAPICallForImageView={true}
            closeFn={() => setModalVisibility(false)}
            document={deliveryDoc}
            deliveryId={deliveryId}
            mainDeliveryID={mainDeliveryID}
            docTypeList={docList}
            convertBytesToKb={convertBytesToKb}
            displayAlert={displayAlert}
            onCompressedImgSave={onCompressedImgSave}
            isShowActionForMpDelivery={!isShowActionForMpDelivery}
            deliveryStatus={deliveryStatus}
            saleOrderId={saleOrderId}
            setDelivery={setDelivery}
            documentDeliveryDetails={deliveryDetails}
            selectedContainer={selectedContainer}
            isBrandDelivery={isBrandDelivery}
            isVendorDelivery={isVendorDelivery}
          />
        )}
      </div>
    </React.Suspense>
  );
};

export default DocumentCard;
