import React from 'react';
import { useRegionsMap } from '../../hooks';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { DELIVERY_STATUS, ENDORSEMENT_STATUS } from '../Constants/Constants';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

const OverlayCell = ({ overlay, hideOverlay, children, ...restProps }) => {
  if (hideOverlay) return children;
  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      key={'bottom'}
      placement={'top'}
      overlay={
        <Popover id={`popover-positioned-date`}>
          <Popover.Content>{overlay}</Popover.Content>
        </Popover>
      }
      {...restProps}>
      {children}
    </OverlayTrigger>
  );
};
const CheckboxCell = (props) => {
  const {
    getToggleSelectionProps,
    isCheckboxDisabled,
    isAllSelected,
    setAllSelected,
    ...restProps
  } = props;
  return (
    <div>
      <input
        {...getToggleSelectionProps()}
        disabled={isCheckboxDisabled}
        type="checkbox"
        {...restProps}
      />
    </div>
  );
};

const BulkDeliveryCheckBoxCell = (props) => {
  const {
    getToggleSelectionProps,
    isCheckboxDisabled,
    isAllSelected,
    setAllSelected,
    row,
    ...restProps
  } = props;

  const toggleSelectionProps = getToggleSelectionProps();

  const isChecked = toggleSelectionProps.checked && !isCheckboxDisabled;

  const updatedToggleSelectionProps = {
    ...toggleSelectionProps,
    checked: isChecked,
  };

  row.isSelected = isChecked;
  return (
    <div>
      <input
        {...updatedToggleSelectionProps}
        checked={isChecked}
        disabled={isCheckboxDisabled}
        type="checkbox"
        {...restProps}
      />
    </div>
  );
};

const DeliveryStatus = (props) => {
  const { status = {} } = {
    ...props,
  };

  const statusColor = Object.values(DELIVERY_STATUS).find((e) => e.id === status)?.colors;
  return (
    <div style={{ display: 'flex', verticalAlign: 'middle', color: statusColor }}>
      <RadioButtonCheckedIcon
        style={{
          width: '14px',
          height: 'auto',
          marginTop: '2px',
        }}
      />

      <span style={{ marginLeft: '5px' }}> {DELIVERY_STATUS[status]?.displayText || 'NA'}</span>
    </div>
  );
};

const Region = ({ stateId, districtId }) => {
  const statesMap = useRegionsMap();
  const state = statesMap[stateId] || {};
  const district = (state?.districts && state?.districts[districtId]) || {};
  return (
    <OverlayCell overlay={[district.name, state.name].filter(Boolean).join(', ')}>
      <span>{[district.name, state.code].filter(Boolean).join(', ') || 'NA'}</span>
    </OverlayCell>
  );
};

const EndorsementStatus = (props) => {
  const { status = {} } = {
    ...props,
  };

  const statusColor = Object.values(ENDORSEMENT_STATUS).find((e) => e.id === status)?.colors;
  return (
    <div style={{ display: 'flex', verticalAlign: 'middle', color: statusColor }}>
      {/*<RadioButtonCheckedIcon
        style={{
          width: '14px',
          height: 'auto',
          marginTop: '2px',
        }}
      />*/}

      <span style={{ marginLeft: '5px' }}> {ENDORSEMENT_STATUS[status]?.displayText}</span>
    </div>
  );
};

const TableRowCheckBoxCell = (props) => {
  const {
    getToggleSelectionProps,
    isCheckboxDisabled,
    isAllSelected,
    setAllSelected,
    row,
    ...restProps
  } = props;

  const toggleSelectionProps = getToggleSelectionProps();

  const isChecked = toggleSelectionProps.checked && !isCheckboxDisabled;

  const updatedToggleSelectionProps = {
    ...toggleSelectionProps,
    checked: isChecked,
  };

  row.isSelected = isChecked;
  return (
    <div>
      <input
        {...updatedToggleSelectionProps}
        checked={isChecked}
        disabled={isCheckboxDisabled}
        type="checkbox"
        {...restProps}
      />
    </div>
  );
};

export {
  CheckboxCell,
  Region,
  OverlayCell,
  EndorsementStatus,
  DeliveryStatus,
  TableRowCheckBoxCell,
  BulkDeliveryCheckBoxCell,
};
